/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
const DocumentAuditing = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  useScript(withPrefix("js/sticky-header.js"))

  useScript(withPrefix("animations/documentauditing/documentauditing.js"))

  useScript(withPrefix("animations/documentauditing/documentauditinglotte.js"))

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "https://documentesign.com/document-auditing/#webpage",
        url: "https://documentesign.com/document-auditing/",
        name: "Electronic signatures for document auditing",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/#website",
        },
        primaryImageOfPage: {
          "@id":
            "https://documentesign.com/static/bcc56467c53b14453fd65055583f769a/home-benefits-img.jpg#primaryimage",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Simplify digital signature document audits and compliance with document eSign solution.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/document-auditing/"],
          },
        ],
      },
    ],
  }

  return (
    <>
      <Layout>
        <SEO
          title="Electronic signatures for document auditing | Document eSign"
          description="Simplify digital signature document audits and compliance with document eSign solution"
          schemaMarkup={schema}
        />

        <div className="feature-details doc-auditing">
          <div className="intro show-on-scroll ani-in">
            <div className="container position-relative mb-md-n5">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center pt-lg-5">
                  <div className="h4 pt-5 pb-3">DOCUMENT AUDITING</div>
                  <h1 className="mb-lg-n4">
                    Track and manage electronic signature documents audit trail
                  </h1>
                </div>
                <div className="col-md-6 offset-md-3 mt-md-n5 text-center">
                  <div
                    id="doumentauditing_lottie_container"
                    className="feature-lottie-ani"
                  ></div>
                </div>
              </div>
            </div>
            <div className="bg-shape d-none d-lg-block">
              <svg
                width="2560px"
                height="100px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="none"
                x="0px"
                y="0px"
                viewBox="0 0 2560 100"
                xmlSpace="preserve"
                enableBackground="new 0 0 2560 100"
              >
                <polygon points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>
          </div>
          <div className="container mt-md-5 pt-md-5">
            <div className="row pt-md-4">
              <div className="col-md-6 offset-md-3 text-center show-on-scroll ani-in">
                <p className="text-light-100 pt-4 pt-md-0">
                  Document esign lets you track and manage the audit trail of
                  any document to ensure every signer is adhering to the
                  statutory process and protecting the eSigner from a potential
                  mishap. Our digital signature audit management solution
                  provides legally binding audit evidence that has been
                  intentionally passed to facilitate commerce with the use of
                  electronic signature.
                </p>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark px-md-5 mt-2 mb-2"
                >
                  Get started
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
          <div className="container py-5">
            <div className="card features-bx mb-5">
              <img
                src={withPrefix("images/top-features-bg.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />

              <div className="card-body px-lg-5 mt-md-n5 py-0">
                <div className="row">
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-shield-alt"></i>
                    </div>

                    <div className="h4">High level of security</div>
                    <p className="text-light-100">
                      Our digital signature online services ensure that every
                      alteration in a document or signatures is recorded,
                      safeguarding the integrity of the document.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-list-ol"></i>
                    </div>

                    <div className="h4">Prompt audit trail display</div>
                    <p className="text-light-100">
                      Manage document auditing with Document Esign and get
                      notified every audit trail records that are automatically
                      sent to all parties involved.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-pen-square"></i>
                    </div>

                    <div className="h4">
                      Manage documents already in progress
                    </div>
                    <p className="text-light-100">
                      Manage online documents with Document esign to meet every
                      deadline and stay on top of every document.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-tasks"></i>
                    </div>

                    <div className="h4">Verified signing process history</div>
                    <p className="text-light-100">
                      Document esign document auditing software captures
                      detailed audit trail of every single activity in a
                      document.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature-bx-cta text-center show-on-scroll ani-in">
                <Link to="/features/" className="btn btn-outline-dark">
                  Explore more features{" "}
                  <i className="fas fa-arrow-right pl-2"></i>
                </Link>
              </div>
              <img
                src={withPrefix("images/bottom-features-bg.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default DocumentAuditing
